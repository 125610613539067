<template>
  <section>
    <div class="content-header">
      <h2>Career Resilience Toolkit</h2>
    </div>
    <div class="content-wrapper">
      <!-- <div class="video-player">
        <vue-plyr>
          <video
            controls
            crossorigin
            playsinline
          >
            <source
              size="720"
              src="https://staging.gevc.ca/centennial/tic/videos/career/share/02.mp4"
              type="video/mp4"
            />
          </video>
        </vue-plyr>
        <div class="accordion">
          <accordion
            headerText="Transcript"
            accordionID="transcript01"
          >
            <p>Video descriptive text appears here</p>
          </accordion>
        </div>
      </div> -->
      <p>Centennial College’s <a
          href="https://www.centennialcollege.ca/student-life/career-services-and-co-operative-education/career-resilience/about-the-career-resilience-toolkit/"
          target="_blank"
        >Career Resilience Toolkit</a> encourages a growth mindset and highlights the personal qualities it requires. </p>
      <p>The toolkit includes buttons, <a :href="`${publicPath}docs/personal/Centennial-thought-cards.pdf`" target="_blank" download="">thought cards</a> and a journal, and is centred on five components of resilience:</p>
      <ul>
        <li>Curiosity</li>
        <li>Optimism</li>
        <li>Flexibility and adaptability</li>
        <li>Risk taking</li>
        <li>Persistence</li>
      </ul>
      <p>The thought cards are designed to inspire students and to encourage them to participate in activities that encourage self-reflection related to thoughts, feelings and actions. </p>
      <p>Consider how you might share these thought cards with your students.</p>
      <div class="row">
        <div class="col-md-1">
        </div>
        <div class="col-md-2 ">
          <img
            src="@/assets/img/_illustrations/career-share-02-1.svg"
            class="img-fluid d-block mx-auto pt-4"
            alt=""
          >
        </div>
        <div class="col-md-2">
          <img
            src="@/assets/img/_illustrations/career-share-02-2.svg"
            class="img-fluid d-block mx-auto pt-4"
            alt=""
          >
        </div>
        <div class="col-md-2">
          <img
            src="@/assets/img/_illustrations/career-share-02-3.svg"
            class="img-fluid d-block mx-auto pt-4"
            alt=""
          >
        </div>
        <div class="col-md-2">
          <img
            src="@/assets/img/_illustrations/career-share-02-4.svg"
            class="img-fluid d-block mx-auto pt-4"
            alt=""
          >
        </div>
        <div class="col-md-2">
          <img
            src="@/assets/img/_illustrations/career-share-02-5.svg"
            class="img-fluid d-block mx-auto pt-4"
            alt=""
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
